$details-padding: 0.3em;

details {
    font-size: 1rem;

    border: 1px solid lightgrey;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
    background: #fff;
    border-radius: 8px;
    position: relative;
    width: 170px;
    margin: 1px;


    .summary-title {
        user-select: none;
    }

    &:hover {
        cursor: pointer;
    }

    .summary-content {
        border-top: 1px solid #e2e8f0;
        cursor: default;
        padding: $details-padding;
        font-weight: 300;
        line-height: 1.5;
    }

    summary {
        padding: $details-padding;

        &:focus {
            outline: none;
        }

        &:hover .summary-chevron-up svg {
            opacity: 1;
        }
    }

    .summary-chevron-up svg {
        opacity: 0.5;
    }

    .summary-chevron-up,
    .summary-chevron-down {
        pointer-events: none;
        position: absolute;
        top: 0;
        right: $details-padding;
        /*background: #ffffff;*/
        font-size: 22px;

        svg {
            display: block;
        }
    }

    summary::-webkit-details-marker {
        display: none;
    }
}
