.selectableCategory {
    /*border: 1px solid black;*/
    width: 105px;
    height: 100px;
    border-radius: 5px;
    border: 1px dashed lightgrey;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 5px;
    font-size: 13px;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    box-shadow: 0 0 10px 0 rgb(0 24 128 / 10%);
}

.selectableCategory:hover{
    transform: scale(1.03);
    box-shadow: 0 0 10px 0 rgb(0 24 128 / 20%);
}
