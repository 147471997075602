.market-container{
    background: #1FB284;
    /*padding: 20px 0;*/
    /*padding-top: 108px;*/
    height: 300px!important;
}

.telephone-container{
    position: relative;
}

.join-wrap{
    left: 100px;
    border-left: 1px solid rgba(255,255,255,0.21);
}

.telephone{
    position: absolute;
    bottom: 0px;
    width: 80%;
}

.market-header, .market-content{
    color: #fff;
    letter-spacing: 0.5px;
}

.market-header{
    font-size: 40px;
}

.market-content{
    font-size: 18px;
    font-weight: 400;
}

.apps{
    width: 150px;
    border-radius: 30px;
    box-shadow: 0px 0px 0px 7px rgb(255 255 255 / 40%);
    margin: 0 20px 0 0;
}

.city-inner{
    padding-bottom: 90px !important;
}

@media screen and (min-width: 1367px){
    .market-container{
        height: 300px !important;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1366px) {
    .telephone{
        width: 80%;
    }

}

@media screen and (min-width: 1024px) and (max-width: 1200px){
    /*.market-container{*/
    /*    height: auto !important;*/
    /*}*/

    .telephone{
        width: 95%;
    }

    .join-wrap {
        left: 30px;
    }
}

@media screen and (min-width: 991px) and (max-width: 1023px){
    .market-container{
        /*padding: 30px 0;*/
        height: auto !important;
        /*padding: 0;*/
    }

    .telephone{
        width: 70%;
    }

    .market-header{
        font-size: 25px;
    }

    .market-header span{
        line-height: 10px;
    }

    .market-content{
        font-size: 14px;
    }

    .apps{
        width: 100px;
    }

    .join-wrap {
        left: 30px;
    }
}

@media screen and (min-width: 768px) and (max-width: 990px) {

    .market-container{
        height: auto !important;
    }

    .telephone{
        width: 90%;
    }
}

@media screen and  (max-width: 767px){
    .market-container{
        /*padding: 40px 0;*/
        height: 260px;
        margin-top: 140px !important;
    }

    .join-wrap{
        left: 0px;
        border-left: none;
        padding-top: 1em;
    }

    .telephone{
        width: 40%;
        /*bottom: -22px;*/
    }

    .market-header{
        font-size: 17px;
        line-height: 25px;
    }

    .market-header span{
        line-height: 10px;
    }

    .market-content{
        font-size: 14px;
    }

    .apps{
        width: 120px;
    }

    .city-inner{
        padding-bottom: 25px !important;
    }
}

@media screen and (min-width: 541px) and (max-width: 767px) {

    .telephone{
        width: 35%;
    }
}

@media screen and (min-width: 480px) and (max-width: 540px){
    .telephone{
        width: 43%;
    }

    .city-inner{
        padding-bottom: 50px !important;
    }
}

@media screen and (max-width: 480px){
    .city-inner{
        padding-bottom: 40px !important;
    }
}

@media screen and (max-width: 479px){
    .city-inner{
        padding-bottom: 25px !important;
    }

    .telephone{
        width: 40%;
    }
}

@media screen and (min-width: 351px) and (max-width: 478px){
    .city-inner{
        padding-bottom: 30px !important;
    }

    .telephone{
        width: 48%;
    }
}

@media screen and (min-width: 100px) and (max-width: 350px){
    .market-container{
        margin-top: 100px !important;
    }

    .telephone{
        width: 45%;
    }
}
