.advert-card-img-container {
  position: relative;
  width: 100%;
  /*border-radius: 10px;*/
  overflow: hidden;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.advert-card-img-container-list-view {
  position: relative;
  width: 350px;
  height: 100%;
  overflow: hidden;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.advert-card-img {
  transition: transform 1.5s, filter 1.5s ease-in-out;
  transform-origin: center center;
  filter: brightness(100%);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.advert-card-img-list-view {
  transition: transform 1.5s, filter 1.5s ease-in-out;
  transform-origin: center center;
  filter: brightness(100%);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.advert-card-img:hover,
.advert-card-img-list-view:hover {
  filter: brightness(100%);
  transform: scale(1.2);
}

.vbug-category {
  position: absolute;
  top: -16px;
  z-index: 20;
  padding: 7px 20px 5px 20px;
  color: #fff;
  font-size: 11px;
  border-radius: 4px;
  font-weight: bold;
  box-shadow: 0px 0px 0px 7px rgb(255 255 255 / 40%);
  background: #11986e;
  letter-spacing: 1px;
}

.vbug-view {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 20;
  padding: 4px 12px;
  color: #fff;
  font-size: 11px;
  border-radius: 4px;
  font-weight: bold;
  /*box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);*/
  background: rgb(17 152 110 / 45%);
  letter-spacing: 1px;
}

.category-link {
  color: whitesmoke;
  font-size: 13px;
}

.advert-card-icon {
  font-size: 18px !important;
  color: #11986e !important;
}

.category-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  padding: 10px 20px 0;
  cursor: pointer;
  background: rgba(0, 24, 128, 0.1);
}

.catogory-info-title {
  position: relative;
  top: -5px;
}

.category-info-title a {
  font-size: 18px;
}

.category-info-footer {
  margin-top: 10px;
  padding: 10px 5px 10px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.post-author-item {
  color: white;
}

.post-time {
  position: relative;
  left: 5px;
  color: #dfdfdf;
}

.post-price {
  display: block;
  color: black;
  font-size: 12px;
  font-weight: 600;
}

.item-category-wrap {
  position: relative;
}

.vbug-link {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
  font-size: 15px !important;
}

.vbug-link,
.item-category-wrap {
  /*color: #fff !important;*/
  color: black;
  font-weight: bold;
  font-size: 17px;
  margin-top: 10px;
  margin-left: 2px;
}

.location-icon {
  position: relative;
  top: 7px;
  color: #23b386;
}
