.login-google-btn {
    width: 130px;
    height: 45px;
    display: flex !important;
    justify-content: center !important;
    border-radius: 5px !important;
    background-color: transparent !important;
    border: solid 1px #e6e6e6 !important;
    box-shadow: none !important;
    align-items: center;
    /*background: #E8453C !important;*/
    margin-top: 10px!important;
    color: black !important;
    padding: 0 20px !important;
    font-size: 13px !important;
    cursor: pointer
}

.my-facebook-button-class {
    width: 130px;
    height: 45px;
    margin-left: 5px;
     background-color: transparent;
    border-radius: 5px !important;
    border: solid 1px #e6e6e6;
    /*border-color: grey !important;*/
    align-items: center;
    /*background: #E8453C !important;*/
    margin-top: 10px!important;
    color: #4C6EA8;
    padding: 0 20px !important;
    font-size: 13px !important;
    cursor: pointer;
    display: flex !important;
    justify-content: center !important;
}

.my-facebook-button-class  i, .login-google-btn div{
    /*background: red;*/
    font-size: 20px;
    padding: 0px 10px 0 0 !important;
    margin: 0 !important;
}
