.font-bold {
  font-weight: bold;
}

.chatroom-item {
  cursor: pointer;
  border-bottom: 1px solid #efefef;
  transition: 0.3s;
  border-radius: 10px;
}

.chatroom-item:hover {
  background-color: #ddd !important;
}

.min-height-400 {
  min-height: 400px !important;
}

.max-width-400 {
  max-width: 400px !important;
}

.messaging-box {
  max-width: 750px;
  margin: 0 auto;
  height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.messaging-box div:nth-of-type(2) {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
}

.messaging-box div:nth-of-type(2)::-webkit-scrollbar {
  width: 6px;
}

.messaging-box div:nth-of-type(2)::-webkit-scrollbar-track {
  background-color: #f4f4f4;
}

.messaging-box div:nth-of-type(2)::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 6px;
}

.messaging-box div:nth-of-type(2)::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

.messaging-box div:nth-of-type(3) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}

.messaging-box div:nth-of-type(3) input[type="text"] {
  flex: 1;
  height: 40px;
  padding: 0 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.messaging-box div:nth-of-type(3) button {
  background: #11986e;
  color: white;
  border: 0;
  outline: 0;
  cursor: pointer;
  height: 40px;
  width: 40px;
  font-size: 18px;
  border-radius: 20px;
  margin-left: 10px;
}

.participant-info-section {
  border-bottom: 1px solid #efefef;
  padding: 5px;
}

.participant-info-section button {
  border: 0;
  background: none;
  outline: 0;
  cursor: pointer;
}

.participant-info-section span {
  font-size: 18px;
  color: black;
  margin-left: 5px;
  font-weight: 600;
}

.message {
  background: #efefef;
  padding: 10px;
  padding-bottom: 0;
  color: #000;
  border-radius: 20px;
}

.messageBody {
  margin: 0;
}

.messageDate {
  margin: 0;
  font-size: 12px;
  text-align: end;
  margin-top: 5px;
  color: #777;
}

.currentUserMessage {
  background: #11986e;
  color: white;
  padding: 10px;
  padding-bottom: 0;
  border-radius: 20px;
  max-width: 360px;
}

.currentUserMessageContainer {
  justify-content: flex-end;
}

.messageTrashIcon {
  display: none;
  font-size: 16px;
  border: 0;
  background: none;
  margin-right: 10px;
  cursor: pointer;
  color: #555;
}

.currentUserMessageContainer:hover .messageTrashIcon {
  display: block;
}

.deleteMessageModalScreenContainer {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.deleteMessageModal {
  background: white;
  padding: 12px;
  border-radius: 10px;
}

.deleteMessageModal button {
  background: none;
  border: 0;
  padding: 5px 0;
  cursor: pointer;
}

.deleteMessageModal button:hover {
  background: #efefef;
}

.deleteMessageModal button:nth-of-type(1) {
  border-bottom: 1px solid #efefef;
}

.chatroomName {
  color: #000;
  font-size: 22px;
  font-weight: 600;
  margin: 0;
}

.messagingScreenAdvertTitle {
  color: #007bff;
  margin: 0 30px;
  font-size: 16px;
  font-weight: 800;
  cursor: pointer;
}

.messagingScreenAdvertTitle:hover {
  text-decoration: underline;
}

.chatroomAdvertTitle {
  color: #000;
  font-size: 14px;
  font-weight: 900;
  margin-bottom: 5px;
}

.lastMessageDate {
  font-size: 13px;
  text-align: end;
  margin: 0;
}

.unreadDot {
  height: 20px;
  width: 20px;
  background: red;
  border-radius: 10px;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 900;
}
